<template>
  <div class="d-flex align-items-center flex-wrap justify-content-between mt-2 mb-3">
    <div v-if="orders.length > 0" class="hotels-grid" style="width: 100%">
      <div
        v-for="order of orders"
        :key="order.id"
        class="items my-0"
        style="cursor:pointer; user-select: none;"
        @click="showFullOrder(order)"
      >
        <div
          class="items position-relative w-100 d-flex"
          style="cursor:pointer; user-select: none;  margin: 8px 0;border-radius: 0.457rem; overflow: hidden; height:
           160px;"
        >
          <b-img-lazy
            v-for="(item, index) in order.items.slice(0, 3)"
            :id="index + '1'"
            :key="index"
            :src="
              'https://omnimenuio.imgix.net/' +
                item.url +
                '?ixlib=vue-2.8.4&auto=format&fit=crop&crop=entropy&h=400&w=600&dpr=1&q=75'
            "
            height="auto"
            rounded
            :style="
              `width: ${100 / order.items.length}%; height: 160px;
          min-height: 100px;
          min-width: 33%;
          object-fit: cover;`
            "
          />
          <div
            class="text-center d-flex justify-content-center align-items-center px-1"
            style="position: absolute;z-index: 2;right:0;left:0;bottom: 0;top: 0;
             color: white; background: #00000087"
          >
            <div class="d-flex flex-column" style="width: 95%">
              <div
                class="font-weight-bolder font-medium-3 font-circular"
                style="min-height: 24px; height: 24px; overflow: hidden;
           text-overflow: ellipsis; -webkit-line-clamp: 1; display: -webkit-box; -webkit-box-orient: vertical;"
              >
                Placed: {{ new Date(order.placed).toLocaleString() }}
              </div>
              <div
                class="d-flex justify-content-between align-items-center font-weight-bold font-medium-2"
                style="margin-top: 5px"
              >
                <span>Status: {{ orderStatus[order.status].text }}</span>
                <span>Total: ${{ order.total }}</span>
              </div>
              <div
                class="text-center  font-medium-1"
                style="margin-top: 5px;min-height: 29px; max-height: 38px; overflow: hidden;
           text-overflow: ellipsis; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;"
              >
                {{ order.items.map(item => item.name).join(',') }}
                {{ order.items.map(item => item.name).join(',') }}
              </div>
              <div
                class="d-flex justify-content-between align-items-center font-weight-bold font-medium-2"
                style="margin-top: 5px"
              >
                <b-button
                  v-if="
                    order.status !== 'cancelled' &&
                      order.placed > new Date(Date.now() - 5 * 60 * 1000).toISOString()
                  "
                  :style="
                    `opacity:${
                      order.status !== 'cancelled' &&
                      order.placed > new Date(Date.now() - 5 * 60 * 1000).toISOString()
                        ? 1
                        : 0
                    }`
                  "
                  variant="outline-warning"
                  @click.prevent.stop="$emit('cancel-order', order)"
                  >Cancel</b-button
                >
                <b-button hidden variant="outline-success">Quik Reorder</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-100 text-center pt-3">
      <h4>
        No orders found!
      </h4>
    </div>
  </div>
</template>

<style>
@media all and (max-width: 576px) {
  .hotels-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
@media all and (min-width: 576px) and (max-width: 850px) {
  .hotels-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3%;
  }
}
@media all and (min-width: 850px) and (max-width: 1300px) {
  .hotels-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 3%;
  }
}
@media all and (min-width: 1312px) {
  .hotels-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 3%;
  }
}
.items.card {
  border-radius: 2rem;
}
.items > .card-body {
  padding: 10px 0;
}
.items > .card-body .card-body {
  padding: 0;
}
</style>

<script>
import { BImgLazy, BButton } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BImgLazy,
    BButton,
    // BCol,
    // BRow,
    // BAspect,
    // BNavItemDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BAvatar,
  },
  props: {
    orders: {
      required: true,
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      fetchingOrders: false,
      orderStatus: {
        confirmed: {
          text: 'Preparing',
        },
        placed: {
          text: 'Preparing',
        },
        cancelled: {
          text: 'Cancelled',
        },
      },
      badges: {
        popular: {
          title: 'POPULAR',
          variant: 'primary',
        },
        fast: {
          title: 'FAST DELIVERY',
          variant: 'primary',
        },
        free: {
          title: 'FREE DELIVERY',
          variant: 'primary',
        },
      },
    }
  },
  computed: mapState({
    hotel: state => state.hotel.hotel || {},
    user: state => state.user.user,
    showLogin: state => state.user.showLogin,
    cart: state => state.cart.added,
    cartCount: state =>
      state.cart.added.reduce(
        (item, current) => ({ quantity: item.quantity + current.quantity }),
        { quantity: 0 }
      ).quantity,
  }),
  created() {
    if (this.orders.length === 1) this.showFullOrder(this.orders[0], true)
  },
  methods: {
    ...mapActions({}),
    showFullOrder(order, replace) {
      if (replace) {
        // eslint-disable-next-line no-underscore-dangle
        this.$router.replace({ name: 'order', query: { id: order._id } })
      } else {
        // eslint-disable-next-line no-underscore-dangle
        this.$router.push({ name: 'order', query: { id: order._id } })
      }
    },
  },
}
</script>
