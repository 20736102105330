<template>
  <div>
    <orders-list
      v-if="!fetchingOrders"
      :orders="activeOrders"
      @cancel-order="confirmCancelOrder"
    />
    <div v-else class="d-flex justify-content-center flex-column align-items-center my-5">
      <b-spinner style="width: 3rem; height: 3rem;" variant="dark" />
    </div>
  </div>
</template>

<script>
import OrdersList from '@/components/OrdersList.vue'
import { BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Orders',
  components: {
    OrdersList,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activeOrders: [],
      fetchingOrders: true,
      cancelling: false,
    }
  },
  computed: mapState({
    hotel: state => state.hotel.hotel || {},
    user: state => state.user.user,
    showLogin: state => state.user.showLogin,
    cart: state => state.cart.added,
    cartCount: state =>
      state.cart.added.reduce(
        (item, current) => ({ quantity: item.quantity + current.quantity }),
        { quantity: 0 }
      ).quantity,
  }),
  async created() {
    await this.getActiveOrders()
    this.filter()
  },
  methods: {
    filter(category) {
      this.activeOrders = this.activeOrders.filter(
        restaurant =>
          !category ||
          (restaurant.categories && restaurant.categories.some(cat => cat === category))
      )
    },
    async getActiveOrders() {
      this.fetchingOrders = true
      const { data: orders } = await this.$http.get(
        // eslint-disable-next-line no-underscore-dangle
        `/orders/by-id/${this.user._id}?status=confirmed,ready,cancelled,completed`,
        {
          withCredentials: true,
        }
      )
      this.activeOrders = orders
      this.fetchingOrders = false
    },
    confirmCancelOrder(order) {
      this.$bvModal
        .msgBoxConfirm('Do you want to cancel this order?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'dark',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) this.cancelOrder(order)
        })
    },
    async cancelOrder(order) {
      try {
        this.cancelling = true
        // eslint-disable-next-line no-underscore-dangle
        const { data: response } = await this.$http.patch(`orders/${order._id}/cancel`, {
          reason: 'Customer_Requested',
        })
        if (response.type !== 'success') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${response.display || 'something went wrong! #878dfgh'}`,
            },
          })
          return
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Order Cancelled successfully',
            icon: 'CheckIcon',
            variant: 'dark',
          },
        })
        await this.getActiveOrders()
        this.filter()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${e.response.data.error.message}`,
          },
        })
      } finally {
        this.cancelling = false
      }
    },
  },
}
</script>

<style scoped></style>
