var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center flex-wrap justify-content-between mt-2 mb-3"},[(_vm.orders.length > 0)?_c('div',{staticClass:"hotels-grid",staticStyle:{"width":"100%"}},_vm._l((_vm.orders),function(order){return _c('div',{key:order.id,staticClass:"items my-0",staticStyle:{"cursor":"pointer","user-select":"none"},on:{"click":function($event){return _vm.showFullOrder(order)}}},[_c('div',{staticClass:"items position-relative w-100 d-flex",staticStyle:{"cursor":"pointer","user-select":"none","margin":"8px 0","border-radius":"0.457rem","overflow":"hidden"}},[_vm._l((order.items.slice(0, 3)),function(item,index){return _c('b-img-lazy',{key:index,style:(("width: " + (100 / order.items.length) + "%; height: 160px;\n        min-height: 100px;\n        min-width: 33%;\n        object-fit: cover;")),attrs:{"id":index + '1',"src":'https://omnimenuio.imgix.net/' +
              item.url +
              '?ixlib=vue-2.8.4&auto=format&fit=crop&crop=entropy&h=400&w=600&dpr=1&q=75',"height":"auto","rounded":""}})}),_c('div',{staticClass:"text-center d-flex justify-content-center align-items-center px-1",staticStyle:{"position":"absolute","z-index":"2","right":"0","left":"0","bottom":"0","top":"0","color":"white","background":"#00000087"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"95%"}},[_c('div',{staticClass:"font-weight-bolder font-medium-3 font-circular",staticStyle:{"min-height":"24px","height":"24px","overflow":"hidden","text-overflow":"ellipsis","-webkit-line-clamp":"1","display":"-webkit-box","-webkit-box-orient":"vertical"}},[_vm._v(" Placed: "+_vm._s(new Date(order.placed).toLocaleString())+" ")]),_c('div',{staticClass:"d-flex justify-content-between align-items-center font-weight-bold font-medium-2",staticStyle:{"margin-top":"5px"}},[_c('span',[_vm._v("Status: "+_vm._s(_vm.orderStatus[order.status].text))]),_c('span',[_vm._v("Total: $"+_vm._s(order.total))])]),_c('div',{staticClass:"text-center  font-medium-1",staticStyle:{"margin-top":"5px","min-height":"29px","max-height":"38px","overflow":"hidden","text-overflow":"ellipsis","-webkit-line-clamp":"2","display":"-webkit-box","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(order.items.map(function (item) { return item.name; }).join(','))+" "+_vm._s(order.items.map(function (item) { return item.name; }).join(','))+" ")]),_c('div',{staticClass:"d-flex justify-content-between align-items-center font-weight-bold font-medium-2",staticStyle:{"margin-top":"5px"}},[(
                  order.status !== 'cancelled' &&
                    order.placed > new Date(Date.now() - 5 * 60 * 1000).toISOString()
                )?_c('b-button',{style:(("opacity:" + (order.status !== 'cancelled' &&
                    order.placed > new Date(Date.now() - 5 * 60 * 1000).toISOString()
                      ? 1
                      : 0))),attrs:{"variant":"outline-warning"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('cancel-order', order)}}},[_vm._v("Cancel")]):_vm._e(),_c('b-button',{attrs:{"hidden":"","variant":"outline-success"}},[_vm._v("Quik Reorder")])],1)])])],2)])}),0):_c('div',{staticClass:"w-100 text-center pt-3"},[_c('h4',[_vm._v(" No orders found! ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }